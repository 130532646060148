import React, { Component } from 'react';
import MovieCard from './MovieCard';
import styles from './MovieContainer.module.css'

class MovieContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            filteredItems: []
        }
    }

    componentDidMount() {
        fetch("https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,contentDetails&maxResults=50&playlistId=PLs_rvJpmqLnEZhlEZ5acFPWgEZyMIFaSt&key=AIzaSyDaFpaabiA0pGOGNdwWStjszhaGC5Kn7Ac&order=videoPublishedAt&fields=nextPageToken,items(id,contentDetails,snippet(title,thumbnails(medium)))")
            .then(res => res.json())
            .then(
                (result) => {
                    let items = this.orderItemsByDate(result.items);
                    this.getNextVideos(result.nextPageToken,items);
                   
                },

                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    getNextVideos(token, oldItems) {
        fetch("https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,contentDetails&maxResults=50&playlistId=PLs_rvJpmqLnEZhlEZ5acFPWgEZyMIFaSt&key=AIzaSyDaFpaabiA0pGOGNdwWStjszhaGC5Kn7Ac&order=videoPublishedAt&fields=nextPageToken,items(id,contentDetails,snippet(title,thumbnails(medium)))&pageToken=" + token)
            .then(res => res.json())
            .then(
                (result) => {
                    let allItems = result.items.concat(oldItems);
                    let items = this.orderItemsByDate(allItems);

                    this.setState({
                        isLoaded: true,
                        items: items,
                        filteredItems: items
                    });
                },

                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    orderItemsByDate(items) {
        return items.sort((a, b) => {
            if (a.snippet.title > b.snippet.title)
                return -1;
            else
                return 1;
        });


    }

    getMovieCards() {
        const widgets = [];
        this.state.filteredItems.forEach(element => {
            let id = 0;
            let img = "";
            
            try {
                id = element.contentDetails.videoId;
                img = element.snippet.thumbnails.medium.url
                let date = new Date();
                console.log("test:" + date.getYear())
            } catch (exception) {

            }

            widgets.push(
                <MovieCard playMovie={this.props.playMovie} title={element.snippet.title} img={img} date={element.contentDetails.videoPublishedAt} id={id}></MovieCard>
            )
        });
        return widgets;
    }

    search = (element) => {
        let value = element.target.value.toLowerCase();
        let items = this.state.items;

        if (!value) {
            this.setState({
                filteredItems: items
            })
        }

        this.setState({
            filteredItems: items.filter(element => {
                let res = element.snippet.title.toLowerCase().search(value);
                return (res >= 0);

            })
        })


    }

    render() {
        return (
            <div >
                <div>
                    <input type="text" placeholder="Suche..." onChange={this.search} />
                </div>
                <div className={styles.container}>
                    {this.getMovieCards()}
                </div>

            </div>
        );
    }
}

export default MovieContainer;