import React, { Component } from 'react';
import YouTube from 'react-youtube';
import { FaWindowClose } from 'react-icons/fa';
import styles from './YouTubePlayer.module.css';

class YouTubePlayer extends Component {

    render() {
        let factor = 0.7;
        if (window.innerWidth<500){
            factor = 0.9;
        }
        const width = window.innerWidth * factor;
        const height = width/16 * 9;
        const opts = {
            height: height,
            width: width,
            vq:"hd720",
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 1,
            },
        };

        return (
            <div>
                <div onClick={this.props.onExit} className={styles.close}><FaWindowClose class={styles.icon}/>Fenster Schließen</div>
                <YouTube videoId={this.props.id} opts={opts} onReady={this._onReady} />

            </div>);

    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        //event.target.pauseVideo();
    }
}

export default YouTubePlayer;