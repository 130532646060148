import React, { Component } from 'react';
import styles from './MovieCard.module.css'

class MovieCard extends Component {
    constructor(props) {
        super(props);
        this.state = { movies: [],date: new Date(this.props.date) }
    }
    openLink() {
        this.props.playMovie(this.props.id, this.props.title);
        //window.open(this.props.url);
    }
    render() {
        return (
            <div className={styles.card} onClick={() => { this.openLink() }}>
              
                <div className={styles.cardContainer}>
                    <div className={styles.imageContainer}>
                        <img src={this.props.img} alt="Bild des Films" />
                    </div>
                    
                    <div className={styles.header}>
                        <div className={styles.title}>{this.props.title}</div>
                       
                    </div>
                </div>




            </div>);
    }
}

export default MovieCard;