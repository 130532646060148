import React, { Component } from 'react';
import Header from './components/Header';
import MovieContainer from './components/MovieContainer';
import YouTubePlayer from './components/YouTubePlayer';

class Application extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMovie: false,
            title:"Filme"
        }
    }

    getContent = () => {
        if (this.state.showMovie)
            return (<YouTubePlayer id={this.state.videoId} onExit={this.onExit}/>)
        else
            return (<MovieContainer playMovie={this.playMovie} />)
    }

    onExit = () => {
        this.setState({
            showMovie: false,
            title: "Filme"
            
        })
    }

    playMovie = (id, title) => {
        this.setState({
            showMovie: true,
            videoId: id,
            title: title
        })
    }
    render() {
        return (
            <div>

                <Header title={this.state.title} />
                {this.getContent()}
            </div>);
    }
}

export default Application;